@font-face {
  font-family: Poppins;
  src: url('./fonts/Poppins-Regular.ttf');
  font-weight: normal;
}

@font-face {
  font-family: 'Poppins Bold';
  src: url('./fonts/Poppins-Bold.ttf');
  font-weight: bold;
}

@font-face {
  font-family: Poppins;
  src: url('./fonts/Poppins-Medium.ttf');
  font-weight: 500;
}

@font-face {
  font-family: Prompt;
  src: url('./fonts/Prompt-Regular.ttf');
  font-weight: normal;
}

@font-face {
  font-family: 'Prompt Bold';
  src: url('./fonts/Prompt-Bold.ttf');
  font-weight: bold;
}

@font-face {
  font-family: 'Prompt Medium';
  src: url('./fonts/Prompt-Medium.ttf');
  font-weight: 500;
}

* {
  font-family: Poppins, Prompt, sans-serif;
}

span {
  display: block;
}

.regular {
  font-family: Poppins, Prompt, sans-serif;
}

.medium {
  font-family: 'Poppins Medium', 'Prompt Medium', sans-serif;
}

.bold {
  font-family: 'Poppins Bold', 'Prompt Bold', sans-serif;
}

.ant-message-custom-content {
  display: flex;
  align-items: center;
}

.ant-menu-light .ant-menu-item:hover,
.ant-menu-light .ant-menu-item-active,
.ant-menu-light .ant-menu:not(.ant-menu-inline) .ant-menu-submenu-open,
.ant-menu-light .ant-menu-submenu-active,
.ant-menu-light .ant-menu-submenu-title:hover {
  color: var(--app-primary);
}

.ant-form-large .ant-form-item-label > label {
  height: 20px;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type='number'] {
  -moz-appearance: textfield;
}
